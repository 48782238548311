<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div>
          <!-- 统计 -->
          <div class="data-statistics">
            <div class="item">
              <div class="br">
                <div class="title">账户余额</div>
                <div class="money"><span class="red">{{balanceInfo.balance}}</span>元</div>
              </div>
              <span class="btm" @click="showRecharge">马上充值</span>
            </div>
            <div class="item">
              <div class="br">
                <div class="title">可提现余额</div>
                <div class="money"><span class="blue">{{balanceInfo.ok_money}}</span>元</div>
              </div>
              <span class="btm" @click="showTixian">立即提现</span>
            </div>
            <div class="item">
              <div class="br">
                <div class="title">提现中余额</div>
                <div class="money"><span>{{balanceInfo.tixian_money}}</span>元</div>
              </div>
            </div>
            <div class="item">
              <div class="four">
                <div class="title">总提现金额</div>
                <div class="money"><span>{{balanceInfo.total_money}}</span>元</div>
              </div>
            </div>
          </div>
          <!-- 充值Dialog -->
          <div class="rechargeDialog">
            <el-dialog
              title="余额充值"
              width="720px"
              :visible.sync="dialogVisible"
              @close="closePayDialog"
              >
              <div class="content">
                <div class="name">
                  充值账户：<span>{{accountzi}}</span>
                </div>
                <div class="price">
                  充值金额：
                  <div class="packing">
                    <div @click="selectAmount(index,item)" class="packing-item" :class="{'active': selectAmountIndex === index}" v-for="(item, index) in rechargeAmount" :key="index">
                      {{item}}元
                      <img class="dagou" src="~assets/icons/dagou.png">
                    </div>
                  </div>
                </div>
                <div class="input-num">
                  <div>
                    <el-input-number @mouseenter.native="controls = true" @mouseleave.native="controls = false" placeholder="请输入充值金额,最低1元" :controls="controls" width="300" ref="numInput" v-model="num" size="small" :precision="2" controls-position="right" @change="handleChange" :min="1"></el-input-number>
                  </div>
                  <span class="queding">确定</span>
                </div>
                <div>
                  <div class="payway-btn">
                    <span>支付方式：</span>
                    <div class="way">
                      <div class="payway" @click="selectAmount1(index,item.value)" :class="{'active': selectzhi === index}" v-for="(item, index) in rechargezhi" :key="index">
                        <img class="yue" src="~assets/icons/yue.png">
                        <img class="dagou" src="~assets/icons/dagou.png">
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                    <el-link type="success">推荐使用支付宝支付</el-link>
                  </div>
                </div>
                <div class="input-num">
                  <span @click="confirmRecharge" class="btn">确认充值</span>
                </div>
              </div>
            </el-dialog>
          </div>
          <!-- 提现Dialog -->
          <div class="tixianDialog">
            <el-dialog
              title="资金提现"
              width="560px"
              :visible.sync="tixianDialogVisible"
              @close="closeTixianDialog"
              >
              <div class="content">
                <div class="input-item">
                  <span>到帐账号：</span>
                  <div class="zhxg">
                    <span v-if="alipay">{{'支付宝' + hideCenterPhone(alipay) + '（' + hideStartName(trueName) + '）'}}</span>
                    <span @click="clickSet">点击修改</span>
                  </div>
                </div>
                <div class="input-item number">
                  <span>提现金额：</span>
                  <el-input-number v-model="tixianNum" @mouseenter.native="tixianControls = true" @mouseleave.native="tixianControls = false" :precision="2" controls-position="right" :controls="tixianControls" :min="1"></el-input-number>
                </div>
                <div class="btn-item">
                  <el-button @click="withdrawal" type="primary">立即提现</el-button>
                </div>
              </div>
            </el-dialog>
          </div>
          <!-- 表格 -->
          <div class="table">
            <div class="table-menu">
              <!-- <div> -->
              <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="selectChange">
                <el-menu-item index="0">全部</el-menu-item>
                <el-menu-item index="1">充值</el-menu-item>
                <el-menu-item index="2">消费</el-menu-item>
                <el-menu-item index="3">收入</el-menu-item>
                <el-menu-item index="4">提现</el-menu-item>
                <el-menu-item index="5">管理员转账</el-menu-item>
                <el-menu-item index="6">退款</el-menu-item>
              </el-menu>
              <div class="input-btn">
                <el-input class="el-input" v-model="id" placeholder="查询ID"></el-input>
                <el-button @click="clickQuery" type="primary" size="small">查询</el-button>
                <el-button @click="clickReset" size="small">重置</el-button>
              </div>
            </div>
            <!-- 内容 -->
            <div class="table-main">
              <el-table :data="moneyObj.data" class="el-table-main" :header-cell-style="{background:'#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500'}">
                <el-table-column label="序号">
                  <template slot-scope="scope"><span>{{scope.$index+(moneyObj.current_page - 1) * moneyObj.per_page + 1}}</span></template>
                </el-table-column>
                <el-table-column prop="id" label="ID"></el-table-column>
                <el-table-column prop="order_no" label="流水号"></el-table-column>
                <el-table-column prop="money" label="金额"></el-table-column>
                <el-table-column prop="reason" label="类型"></el-table-column>
                <el-table-column prop="memo" label="描述"></el-table-column>
                <el-table-column label="创建时间">
                  <template slot-scope="scope">{{timeConversion(scope.row.createtime)}}</template>
                </el-table-column>
              </el-table>
              <div class="pagination">
                <el-pagination
                  background
                  @current-change="currentChange"
                  :page-size="moneyObj.per_page"
                  layout="prev, pager, next"
                  :current-page="moneyObj.current_page"
                  :total="moneyObj.total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import { topuprice, moneyInfo, moneylist, getUserMoney, balanceApply } from 'network/api'
import { timeConversion, hideCenterPhone, hideStartName } from 'utils/utils'
// import {topup} from "../../../network/api";

export default {
  components: {
    AppMain
  },
  inject: ['mainReload'],
  data () {
    return {
      activeIndex: '0',
      dialogVisible: false,
      tixianDialogVisible: false,
      rechargeAmount: ['20', '100', '500', '2000'],
      rechargezhi: [{
        name: '支付宝支付',
        value: 'alipay'
      }, {
        name: '微信支付',
        value: 'wechat'
      }],
      amountType: 'alipay',
      selectAmountIndex: 0,
      selectzhi: 0,
      num: undefined,
      tixianNum: 10,
      controls: false,
      tixianControls: false,
      balanceInfo: {},
      moneyObj: {},
      alipay: '',
      accountzi: '',
      trueName: '',
      currentPage: 1,
      moneylogId: '',
      reasonVal: '',
      id: ''
    }
  },
  computed: {
    timeConversion () {
      return timeConversion
    },
    hideCenterPhone () {
      return hideCenterPhone
    },
    hideStartName () {
      return hideStartName
    }
  },
  created () {
    if (this.selectAmountIndex === 0) {
      this.num = 20
    }
    // 获取余额信息
    moneyInfo().then(res => {
      this.balanceInfo = res.data
    })
    // 获取金钱列表
    this.getMoneylist()
    // 获取支付宝账号
    getUserMoney().then(res => {
      // console.log(res.data)
      this.accountzi = res.data.username
      this.alipay = res.data.ali_pay
      this.trueName = res.data.true_name
    })
  },
  methods: {
    // 马上充值
    showRecharge () {
      this.dialogVisible = true
    },
    // 选择充值金额点击
    selectAmount (index, item) {
      this.selectAmountIndex = index
      this.num = item
    },
    // 充值方式
    selectAmount1 (index, value) {
      this.selectzhi = index
      this.amountType = value
    },
    // 关闭Dialog回调
    closePayDialog () {
    },
    // 计数器值改变
    handleChange (val) {
    },
    // 确认充值
    confirmRecharge () {
      if (this.num) {
        topuprice(this.num.toString(), this.amountType).then(res => {
          window.open(res.data.payUrl, '_blank')
        })
      } else {
        this.$message({
          message: '请输入金额',
          type: 'warning'
        })
      }
    },
    // 关闭提现Dialog回调
    closeTixianDialog () {
    },
    // 提现点击
    showTixian () {
      this.tixianDialogVisible = true
    },
    // 点击修改
    clickSet () {
      this.$router.push('/account/base')
    },
    // 获取金钱变动列表
    getMoneylist () {
      moneylist({
        reason: this.reasonVal,
        moneylog_id: this.moneylogId,
        page: this.currentPage
      }).then(res => {
        this.moneyObj = res.data
      })
    },
    currentChange (val) {
      this.currentPage = val
      this.getMoneylist()
    },
    selectChange (index) {
      this.currentPage = 1
      switch (index) {
        case '0':
          this.reasonVal = ''
          this.getMoneylist()
          break
        case '1':
          this.reasonVal = '充值'
          this.getMoneylist()
          break
        case '2':
          this.reasonVal = '下单支付'
          this.getMoneylist()
          break
        case '3':
          this.reasonVal = '邀请奖励'
          this.getMoneylist()
          break
        case '4':
          this.reasonVal = '提现'
          this.getMoneylist()
          break
        case '5':
          this.reasonVal = '管理员转账'
          this.getMoneylist()
          break
        case '6':
          this.reasonVal = '退款'
          this.getMoneylist()
          break
      }
    },
    // 查询
    clickQuery () {
      this.moneylogId = this.id
      this.getMoneylist()
    },
    // 重置
    clickReset () {
      this.mainReload()
    },
    // 余额提现
    withdrawal () {
      if (this.tixianNum > this.balanceInfo.ok_money) {
        this.$message({
          message: '可提现余额不足',
          type: 'warning'
        })
        return false
      }
      balanceApply({
        money: this.tixianNum,
        account: this.alipay,
        name: this.trueName
      }).then(res => {
        if (res.code === 1) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.mainReload()
        }
      })
    }
  }
}
</script>

<style scoped src="styles/views/account/balance.css">
</style>

<style>
.rechargeDialog .el-dialog__header, .tixianDialog .el-dialog__header {
  border-bottom: 1px solid #e8e8e8;
}

.input-num .el-input-number--small {
  width: 180px;
}

.input-item.number .el-input-number {
  width: 324px;
}
</style>
